import angular from 'angular';
import template from './services.template.html'
import controller from './services.controller'

let servicesModule = angular.module('services', [
])
.component('services', {
  template,
  controller,
  controllerAs: 'services',
});

export default servicesModule;
