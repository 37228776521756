import angular from 'angular';
import template from './dropdown-contactform.template.html';
import controller from './dropdown-contactform.controller'

let dropdownContactFormModule = angular.module('DropdownContactForm', [
])
.component('dropdownContactForm', {
  template,
  controller,
  controllerAs: 'dropdownContactForm',
});

export default dropdownContactFormModule;
