import angular from 'angular';
import template from './team.template.html';
import controller from './team.controller';

let teamModule = angular.module('team', [
])
.component('team', {
  template,
  controller,
  controllerAs: 'team',
});

export default teamModule;
