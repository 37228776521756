import headerImage from '../../assets/img/services/header-img.png';
import briefcaseImage from '../../assets/img/services/briefcase-img.png';
import meetingImage from '../../assets/img/services/meeting-img.png';
import microscopeImage from '../../assets/img/services/microscope-img.png';
import paletteImage from '../../assets/img/services/palette-img.png';
import planetImage from '../../assets/img/services/planet-img.png';
import timelineImage from '../../assets/img/services/timeline-img.png';
import networkImage from '../../assets/img/services/network-img.png';
import translateImage from '../../assets/img/services/translate-img.png';
import chartImage from '../../assets/img/services/chart-img.png';
import windowAdd from '../../assets/img/services/window-add.png';
import spaceship from '../../assets/img/services/spaceship.png';
import chartBar from '../../assets/img/services/chart-bar.png';
import journey from '../../assets/img/services/journey.png';
import plantGround from '../../assets/img/services/plant-ground.png';

class ServicesController {
  
  constructor() {
    this.name = 'services';
    this.card = 'platform';
  }

  initilaizeImages() {
    this.headerImage = headerImage;

    this.briefcaseImage = briefcaseImage;
    this.meetingImage = meetingImage;
    this.microscopeImage = microscopeImage;

    this.paletteImage = paletteImage;
    this.planetImage = planetImage;
    this.timelineImage = timelineImage;
    this.networkImage = networkImage;
    this.translateImage = translateImage;
    this.chartImage = chartImage;

    this.windowAdd = windowAdd;
    this.spaceship = spaceship;

    this.chartBar = chartBar;
    this.journey = journey;
    this.plantGround = plantGround;
  }

  switchCards(card) {

    switch(card) {
      case 'platform':
        this.card = 'platform';
        break;
      case 'research':
        this.card = 'research';
        break;
      case 'consultancy':
        this.card = 'consultancy';
        break;
      default:
        this.card = 'platform';
    }
  }

  $onInit() {
    this.initilaizeImages();  
  }
}
  
export default ServicesController;
