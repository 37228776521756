class DropdownContactForm {

    constructor($timeout, $state, $transitions) {
        var self = this;
        this.$timeout = $timeout;
        this.$state = $state;

        $transitions.onSuccess({}, function(transition) {
            if(self.hasOwnProperty('showDropDown') &&  self.showDropDown)
                self.showDropDown = false;
        });
    }

    $onInit() {
        var self = this;
            this.$timeout(function () {
                if (self.$state.current.name !== 'contact') {
                    self.showDropDown = true;
                }
            }, 7000);
    }



}

DropdownContactForm.$inject = ['$timeout', '$state', '$transitions'];

export default DropdownContactForm;
