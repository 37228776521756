import angular from 'angular';
import template from './platform.template.html'
import controller from './platform.controller'

let platformModule = angular.module('platform', [
])
.component('platform', {
  template,
  controller,
  controllerAs: 'platform',
});

export default platformModule;
