import headerImage from '../../assets/img/logo-brown-orange.png';

class HeaderController {
  
  constructor() {
    this.name = 'header'
  }

  $onInit() {
    this.headerImage = headerImage;

    $(document).on('click','.navbar-collapse.show',function(e) {
      $(this).collapse('hide');
    });
  }
}
  
export default HeaderController;
