import angular from 'angular';
import template from './home.template.html'
import controller from './home.controller'

let homeModule = angular.module('home', [
])
.component('home', {
  template,
  controller,
  controllerAs: 'home',
});

export default homeModule;
