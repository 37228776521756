import headerImage from '../../assets/img/ai/header-img.png';
import summariesImage from '../../assets/img/ai/summaries.png';
import transcriptionImage from '../../assets/img/ai/transcriptions.png';
import translationsImage from '../../assets/img/ai/translations.png';
import textanalyticsImage from '../../assets/img/ai/textanalytics.png';

import windowAdd from '../../assets/img/services/window-add.png';
import goalImage from '../../assets/img/team/goal-img.png';
import multipleImage from '../../assets/img/team/multiple-img.png';
import journey from '../../assets/img/services/journey.png';


class AIController {
  
  constructor($timeout, $scope) {
    this.name = 'ai';
    this.$timeout = $timeout;
    this.$scope = $scope;
  }

  $onInit() {
    this.headerImage = headerImage;
    this.summariesImage = summariesImage;
    this.transcriptionImage = transcriptionImage;
    this.translationsImage = translationsImage;
    this.textanalyticsImage = textanalyticsImage;

    this.windowAdd = windowAdd;
    this.goalImage = goalImage;
    this.multipleImage = multipleImage;
    this.journey = journey;
  }
}

AIController.$inject = ['$timeout', '$scope']

export default AIController;
