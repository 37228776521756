import headerImage from '../../assets/img/contact/header-img.png';
import demoImage from '../../assets/img/contact/demo-img.png';
import teamChatImage from '../../assets/img/contact/team-chat-img.png';
import teamImage from '../../assets/img/contact/team-img.png';
import supportImage from '../../assets/img/contact/support-img.png';

class ContactController {

    constructor($http, $location, $document, $timeout, $state, $sce) {
        this.$http = $http;
        this.card = 'demo';
        this.label = 'Request a Demo';
        this.email_add = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        this.$document = $document;
        this.$timeout = $timeout;
        this.$location = $location;
        this.$state = $state;
        this.$sce = $sce;
    }

    initilaizeImages() {
        this.headerImage = headerImage;

        this.demoImage = demoImage;
        this.teamChatImage = teamChatImage;
        this.teamImage = teamImage;
        this.supportImage = supportImage;
    }

    initMap() {
        var myLatLng = {lat: 51.5256164, lng: -0.0836163999999826};
        var map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom  : 15
        });
        var marker = new google.maps.Marker({
            map     : map,
            position: myLatLng,
            title   : '86-90 Paul St, London EC2A 4NE, UK'
        });
    }

    $onInit() {
        var self = this;
        var params = self.$location.search();
        if (params.hasOwnProperty('contactType') && params.contactType) {

            if (params.contactType === 'talk-team') {
                self.card = 'talk-team';
                self.label = 'Talk to the Team';
            }
            if (params.contactType === 'demo') {
                self.card = 'demo';
                self.label = 'Request a Demo';
            }
            if (params.contactType === 'talk-team') {
                self.card = 'talk-team';
                self.label = 'Talk to the Team';
            }

            if (params.sent) {
                self.emailSent = "Thanks, your message was sent successfully. We will be in touch soon.";
            }

            self.$timeout(function () {
                self.$document.scrollToElementAnimated($('#contact-form'));
            }, 500);
        }

        self.initilaizeImages();
        self.initMap();
    }

    submit() {
        var self = this;
        if (self.contactForm.$valid) {
            self.buttonPressed = true;
            var data = {
                name   : this.name,
                email  : this.email,
                phone  : this.phone,
                message: this.message,
                card   : this.card
            };

            this.$http.post('/send_message/send_email.php', data).then(function successCallback(response) {
                if (response.data.result === 'Email sent!') {
                    self.emailSent = "Thanks, your message was sent successfully. We will be in touch soon.";
                    self.name = '';
                    self.email = '';
                    self.phone = '';
                    self.message = '';
                    self.contactForm.$setPristine(true);
                    self.contactForm.$setUntouched(true);
                    if(self.card === 'talk-team' || self.card === 'demo'){
                        self.$state.go('contact', {sent: true, contactType: self.card});
                    }
                }
                else {
                    self.emailNotSent = "Unfortunately your message was not sent. Please try again.";
                }
                self.buttonPressed = false;
            }, function errorCallback(response) {
                self.emailNotSent = "Unfortunately your message was not sent. Please try again.";
                self.buttonPressed = false;
            });
        }
    }
}

ContactController.$inject = ['$http', '$location', '$document', '$timeout', '$state', '$sce'];

export default ContactController;
