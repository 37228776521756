import headerImage from '../../assets/img/work/header-img.png';
import padImage from '../../assets/img/work/pad-img.png';
import digitalDiaries from '../../assets/img/work/digial-diaries.png';
import customerJourney from '../../assets/img/work/customer-journeys.png';
import collaborativeInno from '../../assets/img/work/collaborative-innovation.png';
import discovery from '../../assets/img/work/discovery.png';
import userExperience from '../../assets/img/work/user-experience.png';
import evalPositioningfrom from '../../assets/img/work/evaluation-and-positioning.png';
import localisations from '../../assets/img/work/localisations.jpg';


class WorkController {
  
    constructor($state, $timeout) {
      this.name = 'work';
      this.currentSection = 'digital_diary';
      this.dropdownText = 'Digital Diaries';
      this.$state = $state;
      this.$timeout = $timeout;
    }

    initilaizeImages() {
      this.headerImage = headerImage;
      
      this.padImage = padImage;
      this.digitalDiaries = digitalDiaries;
      this.customerJourney = customerJourney;
      this.collaborativeInno = collaborativeInno;
      this.discovery = discovery;
      this.userExperience = userExperience;
      this.evalPositioningfrom = evalPositioningfrom;
      this.localisations = localisations;
    }

    changeSections(section) {
      switch (section) {
        case 'digital_diary':
          this.currentSection = section;
          break;
        case 'customer_journey':
          this.currentSection = section;
          break;
        case 'collab_inno':
          this.currentSection = section;
          break;
        default:
          this.currentSection = 'digital_diary';
          break;
      }
    }

    $onInit() {
      this.initilaizeImages();  

      if (this.$state.params && this.$state.params.section) {
        this.currentSection = this.$state.params.section;

        this.$timeout(function() {
          $('html, body').animate({
            scrollTop: ($('.work-second-body-container').offset().top)
        },500);
        });
      }
    }
}

WorkController.$inject = ['$state', '$timeout']
  
export default WorkController;
