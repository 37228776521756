import 'jquery';
import 'bootstrap';
import angular from 'angular';
import ngAnimate from 'angular-animate';
import uiRouter from '@uirouter/angularjs';

import baseRoute from './base.routes';
import './scss/base.scss';
import * as CookieConsent from 'vanilla-cookieconsent';

import Home from './components/home/home.component';
import Header from './components/header/header.component';
import Platform from './components/platform/platform.component';
import AI from './components/ai/ai.component';
import Services from './components/services/services.component';
import Work from './components/work/work.component';
import Team from './components/team/team.component';
import Contact from './components/contact/contact.component';
import Footer from './components/footer/footer.component';
import Privacy from './components/footer/privacy.component';
import DropdownContactForm from './components/dropdown-contactform/dropdown-contactform.component';
import duScroll from 'angular-scroll';

import 'ng-meta';
import 'angular-cookies';

angular.module('base', [
    uiRouter,
    'ngMeta',
    'ngCookies',
    ngAnimate,
    duScroll,
    Home.name,
    Header.name,
    Footer.name,
    Platform.name,
    AI.name,
    Services.name,
    Work.name,
    Team.name,
    Privacy.name,
    Contact.name,
    DropdownContactForm.name

])
    .config(baseRoute)
    .config(basicConfig)
    .run(runFunc);

function basicConfig($sceDelegateProvider, $locationProvider) {
    $locationProvider.hashPrefix('');

    $sceDelegateProvider.resourceUrlWhitelist([
        // Allow same origin resource loads.
        'self',
        // Allow loading from our assets domain.  Notice the difference between * and **.
        'http://view.vzaar.com/**',
        'https://www.youtube.com/**'
    ]);

}

function sendPreferences () {
    gtag('consent', 'update', {
        analytics_storage: CookieConsent.acceptedCategory('analytics') ? 'granted' : 'denied',
        functionality_storage: CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied',
        personalization_storage: CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
        security_storage: CookieConsent.acceptedCategory('necessary') ? 'granted' : 'denied',
        ad_storage: CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
        ad_user_data: CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
        ad_personalization: CookieConsent.acceptedCategory('marketing') ? 'granted' : 'denied',
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'Cookie preferences',
        cookies: CookieConsent.getUserPreferences().acceptedCategories.join(' '),
    });
    window.dataLayer.push({
		'event': 'cookie_consent_update',
	});
}

function runFunc($rootScope, $state, ngMeta, $transitions, $location, $cookies) {
    $rootScope.$state = $state;
    ngMeta.init();

    angular.element(document).ready(function () {
         CookieConsent.run({
             onConsent: () => {
                sendPreferences();
            },
            onChange: () => {
                sendPreferences();
            },
            categories: {
                necessary: {
                    enabled: true,  // this category is enabled by default
                    readOnly: true  // this category cannot be disabled
                },
                analytics: {},
                marketing: {},
            },
            guiOptions: {
                consentModal: {
                    layout: 'bar',
                    position: 'bottom',
                    flipButtons: false,
                    equalWeightButtons: true
                },
                preferencesModal: {
                    layout: 'box',
                    flipButtons: false,
                    equalWeightButtons: true
                }
            },
            language: {
                default: 'en',
                translations: {
                    en: {
                        consentModal: {
                            title: 'We use cookies',
                            description: 'We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.' +
                                ' We also share information about your use of our site with our social media, advertising and analytics partners who' +
                                ' may combine it with other information that you’ve provided to them or that they’ve collected from your use of their' +
                                ' services.',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage Individual preferences',
                        },
                        preferencesModal: {
                            title: 'Manage cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Accept current selection',
                            closeIconLabel: 'Close modal',
                            sections: [
                                {
                                    description: 'We use cookies to personalise content and ads, to provide social media features and to analyse our' +
                                        ' traffic.  We also share information about your use of our site with our social media, advertising and ' +
                                        'analytics partners who may combine it with other information that you’ve provided to them or that they’ve' +
                                        ' collected from your use of their services.',
                                },
                                {
                                    title: 'Strictly Necessary cookies',
                                    description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                                    linkedCategory: 'necessary'
                                },
                                {
                                    title: 'Performance and Analytics',
                                    description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                                    linkedCategory: 'analytics'
                                },
                                {
                                    title: 'Marketing',
                                    description: 'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
                                    linkedCategory: 'marketing'
                                },
                                {
                                    title: 'More information',
                                    description: 'Learn more about who we are, how you can <a href="/contact/">contact us</a> and how we process personal data in our <a href="/privacy/">Privacy Policy</a>.'
                                }
                            ]
                        }
                    }
                }
            }
        });
     });

    var params = $location.search();
    if(params.hasOwnProperty('msource') && params.msource){
        $cookies.put('msource', params.msource);
    }
    if(params.hasOwnProperty('gclid') && params.gclid){
        $cookies.put('gclid', params.gclid);
    }
    if(params.hasOwnProperty('gcampaign') && params.gcampaign){
        $cookies.put('gcampaign', params.gcampaign);
    }

    $transitions.onSuccess({}, function() {

        // Track a new page using setPath:
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '#!' + $location.path()]);

        // Track the page view for the new page
        _hsq.push(['trackPageView']);
    });
}

basicConfig.$inject = ['$sceDelegateProvider', '$locationProvider'];
runFunc.$inject = ['$rootScope', '$state', 'ngMeta', '$transitions', '$location', '$cookies'];
