import angular from 'angular';
import template from './work.template.html'
import controller from './work.controller'

let workModule = angular.module('work', [
])
.component('work', {
  template,
  controller,
  controllerAs: 'work',
});

export default workModule;
