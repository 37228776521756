import headerImage from '../../assets/img/platform/header-img.png';
import devicesImage from '../../assets/img/platform/devices-dashboard.png';
import devicesRetImage from '../../assets/img/platform/devices-ret-dashboard.png';
import discussionTask from '../../assets/img/platform/discussion-task.png';
import discussionRetTask from '../../assets/img/platform/discussion-ret-task.png';
import videoTask from '../../assets/img/platform/video-task.png';
import videoRetTask from '../../assets/img/platform/video-ret-task.png';
import screencastTask from '../../assets/img/platform/screencast-task.png';
import screencastRetTask from '../../assets/img/platform/screencast-ret-task.png';
import conceptEvalTask from '../../assets/img/platform/concept-eval-task.png';
import conceptEvalRetTask from '../../assets/img/platform/concept-eval-ret-task.png';
import pollTask from '../../assets/img/platform/poll-task.png';
import pollRetTask from '../../assets/img/platform/poll-ret-task.png';
import diaryTask from '../../assets/img/platform/diary-task.png';
import diaryRetTask from '../../assets/img/platform/diary-ret-task.png';
import imageTask from '../../assets/img/platform/image-task.png';
import imageRetTask from '../../assets/img/platform/image-ret-task.png';
import bespokeImage from '../../assets/img/platform/bespoke-img.png';
import scheduleImage from '../../assets/img/platform/schedule-img.png';
import mobileOptImage from '../../assets/img/platform/mobile-opt-img.png';
import liveChatTask from '../../assets/img/platform/livechat.png';
import liveChatRetTask from '../../assets/img/platform/livechat-ret.png';
import ideasBoardTask from '../../assets/img/platform/ideas-board.png';
import ideasBoardRetTask from '../../assets/img/platform/ideas-board-ret.png';
import mediaMapTask from '../../assets/img/platform/mediamap.png';
import mediaMapRetTask from '../../assets/img/platform/mediamap-ret.png';
import socialMediaImage from '../../assets/img/platform/social-media-img.png';
import projManImage from '../../assets/img/platform/proj-man-img.png';
import screenSegImage from '../../assets/img/platform/screen-seg-img.png';
import colabAnalysisImage from '../../assets/img/platform/colab-analysis-img.png';
import curatedContentImage from '../../assets/img/platform/curated-content-img.png';
import platform1 from '../../assets/img/platform/platform1.png';
import platform2 from '../../assets/img/platform/platform2.png';
import platform3 from '../../assets/img/platform/platform3.png';

class PlatformController {
  
  constructor($timeout, $scope) {
    this.name = 'platform';
    this.$timeout = $timeout;
    this.$scope = $scope;
  }

  initilaizeImages() {
    this.headerImage = headerImage;

    this.devicesImage = devicesImage;
    this.devicesRetImage = devicesRetImage;
    this.discussionTask = discussionTask;
    this.discussionRetTask = discussionRetTask;
    this.videoTask = videoTask;
    this.videoRetTask = videoRetTask;
    this.screencastTask = screencastTask;
    this.screencastRetTask = screencastRetTask;
    this.conceptEvalTask = conceptEvalTask;
    this.conceptEvalRetTask = conceptEvalRetTask;
    this.pollTask = pollTask;
    this.pollRetTask = pollRetTask;
    this.diaryTask = diaryTask;
    this.diaryRetTask = diaryRetTask;
    this.imageTask = imageTask;
    this.imageRetTask = imageRetTask;
    this.liveChatTask = liveChatTask;
    this.liveChatRetTask = liveChatRetTask;
    this.ideasBoardTask = ideasBoardTask;
    this.ideasBoardRetTask = ideasBoardRetTask;
    this.mediaMapTask = mediaMapTask;
    this.mediaMapRetTask = mediaMapRetTask;


    this.bespokeImage = bespokeImage;
    this.scheduleImage = scheduleImage;
    this.mobileOptImage = mobileOptImage;
    this.socialMediaImage = socialMediaImage;
    this.projManImage = projManImage;
    this.screenSegImage = screenSegImage;
    this.colabAnalysisImage = colabAnalysisImage;
    this.curatedContentImage = curatedContentImage;

    this.platform1 = platform1;
    this.platform2 = platform2;
    this.platform3 = platform3;
  }

  initCarouselListener() {
    let self = this;

    this.$timeout(function() {
      $('#clients-carousel3').carousel({
        interval: 100000
      })

      $('#clients-carousel3').on('slide.bs.carousel', function (data) {
        self.$timeout(function() {
          switch(data.to) {
            case 1:
              self.carouselBg = 'live-chat';
              break;
            case 2:
              self.carouselBg = 'discussion';
              break;
            case 3:
              self.carouselBg = 'video-task';
              break;
            case 4:
              self.carouselBg = 'image-task';
              break;
            case 5:
              self.carouselBg = 'screencast';
              break;
            case 6:
              self.carouselBg = 'ideas-board';
              break;
            case 7:
              self.carouselBg = 'concept-eval';
              break;
            case 8:
              self.carouselBg = 'media-map';
              break;
            case 9:
              self.carouselBg = 'poll';
              break;
            case 10:
              self.carouselBg = 'diary';
              break;
            default:
              self.carouselBg = 'default';
          }
        });
      }); 
    });
  }

  goToTask(number) {
    this.$timeout(function() {
      $('#clients-carousel3').carousel(number);
    });
  }

  $onInit() {
    this.initilaizeImages();  
    this.initCarouselListener();

    this.carouselBg = 'default';
  }
}

PlatformController.$inject = ['$timeout', '$scope']

export default PlatformController;
