import angular from 'angular';
import * as CookieConsent from 'vanilla-cookieconsent';

class PrivacyController {
    constructor($http, $location, $document, $timeout, $state, $sce) {

    }

    showCookiePreferences () {
        CookieConsent.showPreferences();
    }
}

PrivacyController.$inject = ['$http', '$location', '$document', '$timeout', '$state', '$sce'];

export default PrivacyController;
