import angular from 'angular';
import template from './header.template.html'
import controller from './header.controller'

let headerModule = angular.module('header', [
])
.component('header', {
  template,
  controller,
  controllerAs: 'header',
});

export default headerModule;
