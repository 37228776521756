import angular from 'angular';
import template from './contact.template.html';
import controller from './contact.controller'

let contactModule = angular.module('contact', [
])
.component('contact', {
  template,
  controller,
  controllerAs: 'contact',
});

export default contactModule;
