import angular from 'angular';
import template from './ai.template.html';
import controller from './ai.controller';

let aiModule = angular.module('ai', [
])
.component('ai', {
  template,
  controller,
  controllerAs: 'ai',
});

export default aiModule;
