import angular from 'angular';
import template from './footer.template.html';

let footerModule = angular.module('footer', [
])
.component('footer', {
  template,
});

export default footerModule;
