import Datamap from 'datamaps';

import headerImage from '../../assets/img/homepage/header_image.png';
import laptopPhoneImage from '../../assets/img/homepage/laptop-phone-img.png';
import playbuttonImage from '../../assets/img/homepage/play-button.png';
import digitalDiaryImage from '../../assets/img/homepage/digitaldiary-image.png';
import cusJourneyImage from '../../assets/img/homepage/cus-journey-img.png';
import colabInnoImage from '../../assets/img/homepage/colab-inno-img.png';
import discoveryImage from '../../assets/img/homepage/discovery-image.png';
import uxImage from '../../assets/img/homepage/ux-img.png';
import evalPosImage from '../../assets/img/homepage/eval-pos-img.png';
import localeImage from '../../assets/img/homepage/locale.png';
import mapImage from '../../assets/img/homepage/map-img.png';
import worldIcon from '../../assets/img/homepage/world-icon.png';
import chatIcon from '../../assets/img/homepage/chat-icon.png';
import languageIcon from '../../assets/img/homepage/language-icon.png';

class HomeController {

    constructor($state, $timeout) {
      this.name = 'home';
      this.$state = $state;
      this.$timeout = $timeout;
      this.videoUrl = "https://www.youtube.com/embed/i73UYb0UiB8?rel=0&amp;showinfo=0&amp;autoplay=1";
    }

    initilaizeImages() {
      this.headerImage = headerImage;
      this.laptopPhoneImage = laptopPhoneImage;
      this.playbuttonImage = playbuttonImage;
      
      this.digitalDiaryImage = digitalDiaryImage;
      this.cusJourneyImage = cusJourneyImage;
      this.colabInnoImage = colabInnoImage;
      this.discoveryImage = discoveryImage;
      this.uxImage = uxImage;
      this.evalPosImage = evalPosImage;
      this.localeImage = localeImage;

      this.mapImage = mapImage;
      this.worldIcon = worldIcon;
      this.chatIcon = chatIcon;
      this.languageIcon = languageIcon;
    }

    goToState(state) {
      this.$state.transitionTo('work', {'section': state}, {reload: true, notify: true});
    }

    $onInit() {
      this.initilaizeImages();

      this.$timeout(function() {
        var map = new Datamap({
            element: document.getElementById('map-container'),
            projection: 'mercator',
            responsive: true,
            fills: {
              defaultFill: '#b3d5e7',
              countries: '#5f9cc9'
            },
            data: {
              USA: { fillKey: "countries" },
              CAN: { fillKey: "countries" },
              CHN: { fillKey: "countries" },
              GBR: { fillKey: "countries" },
              JPN: { fillKey: "countries" },
              FRA: { fillKey: "countries" },
              RUS: { fillKey: "countries" },
              ESP: { fillKey: "countries" },
              BRA: { fillKey: "countries" },
              KOR: { fillKey: "countries" },
              TWN: { fillKey: "countries" },
              AUS: { fillKey: "countries" },
              HKG: { fillKey: "countries" },
              MEX: { fillKey: "countries" },
              DEU: { fillKey: "countries" },
              IND: { fillKey: "countries" },
              ITA: { fillKey: "countries" },
              NLD: { fillKey: "countries" },
              THA: { fillKey: "countries" },
              IND: { fillKey: "countries" },
              IDN: { fillKey: "countries" },
              ZAF: { fillKey: "countries" },
              POL: { fillKey: "countries" },
              VNM: { fillKey: "countries" },
              SGP: { fillKey: "countries" },
              ARG: { fillKey: "countries" },
              MYS: { fillKey: "countries" },
              CHE: { fillKey: "countries" },
              TUR: { fillKey: "countries" },
              GRC: { fillKey: "countries" },
              IRL: { fillKey: "countries" },
              SWE: { fillKey: "countries" },
              SAU: { fillKey: "countries" },
              PRT: { fillKey: "countries" },
              PHL: { fillKey: "countries" },
              EGY: { fillKey: "countries" },
              ARE: { fillKey: "countries" },
              HRV: { fillKey: "countries" },
              ROU: { fillKey: "countries" },
              NZL: { fillKey: "countries" },
              BEL: { fillKey: "countries" },
              COL: { fillKey: "countries" },
              AUT: { fillKey: "countries" },
              CHL: { fillKey: "countries" },
              CYP: { fillKey: "countries" },
              DNK: { fillKey: "countries" },
              KEN: { fillKey: "countries" },
              ISR: { fillKey: "countries" },
              HUN: { fillKey: "countries" },
              NOR: { fillKey: "countries" },
              UKR: { fillKey: "countries" },
              VEN: { fillKey: "countries" },
              CZE: { fillKey: "countries" },
              NGA: { fillKey: "countries" },
              KHM: { fillKey: "countries" },
              DOM: { fillKey: "countries" },
              BGR: { fillKey: "countries" },
              FIN: { fillKey: "countries" },
              SRB: { fillKey: "countries" },
              NPL: { fillKey: "countries" },
              ECU: { fillKey: "countries" },
              LBN: { fillKey: "countries" },
              LKA: { fillKey: "countries" },
              PAK: { fillKey: "countries" },
              MNE: { fillKey: "countries" },
              LTU: { fillKey: "countries" },
              MLT: { fillKey: "countries" },
              GEO: { fillKey: "countries" },
              SVK: { fillKey: "countries" },
              BGD: { fillKey: "countries" },
              LVA: { fillKey: "countries" },
              MUS: { fillKey: "countries" },
              DZA: { fillKey: "countries" },
              IRN: { fillKey: "countries" },
              JAM: { fillKey: "countries" },
              MKD: { fillKey: "countries" },
              EST: { fillKey: "countries" },
              MAR: { fillKey: "countries" },
              OMN: { fillKey: "countries" },
              SVN: { fillKey: "countries" },
              BHR: { fillKey: "countries" },
              IRQ: { fillKey: "countries" },
              BLR: { fillKey: "countries" },
              MMR: { fillKey: "countries" },
              JOR: { fillKey: "countries" },
              JEY: { fillKey: "countries" },
              PER: { fillKey: "countries" },
              QAT: { fillKey: "countries" },
              TUN: { fillKey: "countries" },
              ISL: { fillKey: "countries" },
              LUX: { fillKey: "countries" },
              BIH: { fillKey: "countries" },
              BWA: { fillKey: "countries" },
            },
            geographyConfig: {
              highlightFillColor: '#98d7cf',
              highlightBorderColor: '#ffffff'
            }
        });

        $(window).on('resize', function() {
          map.resize();
        });
      });
    }
}

HomeController.$inject = ['$state', '$timeout'];

export default HomeController;
