import angular from 'angular';
import template from './privacy.html';
import controller from './privacy.controller'

let privacyModule = angular.module('privacy', [
])
.component('privacy', {
  template,
  controller,
  controllerAs: 'privacy',
});

export default privacyModule;
