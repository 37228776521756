import terms from './components/footer/terms_cond.html';
import clientTerms from './components/footer/client-terms.html';
import platformDemo from './components/footer/platform-demo.html';
import showreelsDemo from './components/footer/showreels-demo.html';
import analysisDemo from './components/footer/analysis-demo.html';
import analysisDemoFR from './components/footer/analysis-demo-fr.html';
import christmas2018 from './components/footer/christmas.html';
import liveChatDemo from './components/footer/live-chat-demo.html';

let baseRoute = ($stateProvider, $urlRouterProvider, $locationProvider, ngMetaProvider) => {

    $urlRouterProvider.otherwise('/');
    ngMetaProvider.setDefaultTitle('incling is an online consumer insight consultancy');

    $stateProvider
        .state('home', {
            url  : '/',
            views: {
                'content': {
                    template: '<home></home>'
                }
            },
            data : {
                meta: {
                    'title'      : 'incling - Online market research | Online Communities',
                    'description': 'We specialise in building bespoke digital solutions to generate rich insight and solve a wealth of business challenges.'
                }
            }
        })
        .state('platform', {
            url  : '/platform/',
            views: {
                'content': {
                    template: '<platform></platform>'
                }
            },
            data : {
                meta: {
                    'title'      : 'incling\'s proprietary online communities software',
                    'description': 'Our proprietary platform has been built by researchers and incling’s own in-house developers. We give you access to a full range of tools, carefully designed to be intuitive, engaging and secure.'
                }
            }
        })
        .state('ai', {
            url  : '/qualitative-ai-analysis/',
            views: {
                'content': {
                    template: '<ai></ai>'
                }
            },
            data : {
                meta: {
                    'title'      : 'incling\'s AI tools for qualitative research, data analysis, transcription and translation',
                    'description': 'AI tools have been designed to help you streamline your qualitative research with the inclusion of AI' +
                        ' generated insight summaries with supporting quotes. Our AI translation and transcription tools also make global research' +
                        ' accessible to all'
                }
            }
        })
        .state('services', {
            url  : '/services/',
            views: {
                'content': {
                    template: '<services></services>'
                }
            },
            data : {
                meta: {
                    'title'      : 'DIY or Fully managed online qualitative research studies - incling',
                    'description': 'Run your own DIY online community or relax and let incling experts manage it for you. We offer completely flexible solutions and tailor each approach. Providing you with a complete online communities solution.'
                }
            }
        })
        .state('work', {
            url  : '/work/?section',
            views: {
                'content': {
                    template: '<work></work>'
                }
            },
            data : {
                meta: {
                    'title'      : 'Results we have achieved with our online communities - incling',
                    'description': 'We’re passionate about the quality of our work and the impact we make on our clients. We love to work together in designing the right solution to solve a challenge.'
                }
            }
        })
        .state('team', {
            url  : '/team/',
            views: {
                'content': {
                    template: '<team></team>'
                }
            },
            data : {
                meta: {
                    'title'      : 'Meet the incling team | About Us',
                    'description': 'We’ve built a business based on the belief that a digital approach will push boundaries and make research more accessible, engaging, and inclusive.'
                }
            }
        })
        .state('contact', {
            url  : '/contact/?contactType&sent',
            views: {
                'content': {
                    template: '<contact></contact>'
                }
            },
            data : {
                meta: {
                    'title'      : 'Contact the incling team | Contact Us',
                    'description': 'Whatever your challenge, we would love to hear from you!'
                }
            }
        })
        .state('terms', {
            url  : '/terms/',
            views: {
                'content': {
                    template: terms
                }
            },
            data : {
                meta: {
                    'title'      : 'Terms and Conditions',
                }
            }
        })
        .state('privacy', {
            url  : '/privacy/',
            views: {
                'content': {
                    template: '<privacy></privacy>'
                }
            },
            data : {
                meta: {
                    'title'      : 'Privacy Policy',
                }
            }
        }).state('clientTerms', {
            url  : '/client-terms/',
            views: {
                'content': {
                    template: clientTerms
                }
            },
            data : {
                meta: {
                    'title'      : 'Client Terms & Conditions',
                }
            }
        }).state('platformDemo', {
            url  : '/platform-demo-XX45968/',
            views: {
                'content': {
                    template: platformDemo
                }
            },
            data : {
                meta: {
                    'title'      : 'incling Platform Demo',
                }
            }
        }).state('showreelsDemo', {
            url  : '/showreels-demo-XX908768/',
            views: {
                'content': {
                    template: showreelsDemo
                }
            },
            data : {
                meta: {
                    'title'      : 'incling Showreels Demo',
                }
            }
        }).state('analysisDemo', {
            url  : '/analysis-demo-XX45968/',
            views: {
                'content': {
                    template: analysisDemo
                }
            },
            data : {
                meta: {
                    'title'      : 'incling Analysis Demo',
                }
            }
        }).state('analysisDemoFR', {
            url  : '/analysis-demo-fr-XX45968/',
            views: {
                'content': {
                    template: analysisDemoFR
                }
            },
            data : {
                meta: {
                    'title'      : 'incling Analysis Demo',
                }
            }
        }).state('liveChatDemo', {
            url  : '/live-chat-demo-XX45968/',
            views: {
                'content': {
                    template: liveChatDemo
                }
            },
            data : {
                meta: {
                    'title'      : 'incling Live Chat Demo',
                }
            }
        }).state('christmas2018', {
            url  : '/christmas-2018/',
            views: {
                'content': {
                    template: christmas2018
                }
            },
            data : {
                meta: {
                    'title'      : 'incling Christmas online communities ',
                }
            }
        });

    $locationProvider.html5Mode(true);
};

baseRoute.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider', 'ngMetaProvider'];

export default baseRoute;
